/**
 * Aztec HC Power of Plus Landing Page
 */

import React, { Component } from "react"
import { Helmet } from 'react-helmet'
import { graphql } from "gatsby"
import get from "lodash/get"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import WufooForm from "react-wufoo-embed"

import LayoutLander from "../components/layoutLander"
import Seo from "../components/seo"
import Curve from "../components/curve"
import { Carousel } from "react-responsive-carousel"
import "../scss/pages/powerofplus.scss"

class PowerOfPlus extends Component {

  componentDidMount() {
    // Replacing &reg; elements
    let regElements = document.querySelectorAll(
      "main h2, main h3, main p, main .btn, .card__desc, .hero__title"
    )
    regElements.forEach(function (el) {
      el.innerHTML = el.innerHTML.replace(/®/g, '<sup>&reg;</sup>');
    })
  }

  render() {
    // set all those variables based on contentful query
    const aztecGif = get(this, "props.data.aztecGif")
    const rowUnitPlus = get(this, "props.data.rowUnitPlus")

    return (
      <LayoutLander bodyClass="aztec-powerofplus">
        <Helmet>
          <meta name="theme-color" content="#e77324" />
        </Helmet>
        <Seo
          title="Power of Plus | AZTEC HC Higher Concentration Granules"
          description="Add more protect to your crops on every pass using up to three SmartCartridges from SIMPAS-applied Solutions."
		      robots="noindex, nofollow"
        />

        <section className="powerofplus-hero hero bg--black">
          <div className="container text-center flow--thick">
              <Img
                fluid={aztecGif.fluid}
                alt="AZTEC® HC Higher Concentration Granules SmartCartridge"
                className="gs__img"
                loading="eager"
              />
              <h1>Add More Protection to Your Fields <br/>on Every Pass With SIMPAS-applied Solutions<sup>®</sup>.</h1>
              <p>Safeguard your crop against corn rootworm with a proven and reliable insecticide — AZTEC® HC Higher Concentration Granules 
                SmartCartridge®. Apply it to the whole field to ensure consistent, broad-spectrum control and to maximize your yield.</p>
              <p>And thanks to the versatility of the SIMPAS® SmartCartridge closed application system, you can add up to two more inputs 
                to apply prescriptively or whole field at the same time. For example, AZTEC HC SmartCartridge <span className="text-plus">plus</span> a 
                micronutrient <span className="text-plus">plus</span> a nematicide. Or AZTEC HC SmartCartridge <span className="text-plus">plus</span> a 
                nematicide <span className="text-plus">plus</span> a soil health product — all in one planter pass.</p>
              <p>Whatever your crops need, SIMPAS-applied Solutions® and the SIMPAS application system can help you efficiently 
                deliver the products you need. Plus, you’ll save time and make more money doing&nbsp;so.</p>
          </div>
          <Curve direction="down" />
        </section>

        <section className="powerofplus-content">
          <div className="container flow--thick">
            <div className="row">
              <div className="col text-left">
                <h2>Only What’s Prescribed.<br/>Precisely Where It’s Needed.</h2>
                <p>The SIMPAS application system is a versatile and effective technology that allows farmers to apply inputs in-furrow to the whole 
                  field while simultaneously treating troubled areas prescriptively with up to two additional products in one pass.</p>
                <p>With a wide portfolio of products available to use — insecticides, nematicides, micronutrients 
                  and soil health products — farmers can now proactively address problem areas in row crops by applying exactly what the soil or plant 
                  needs precisely where it is needed.</p>
                <p>Where once they may have left a problem area untreated and taken the economic consequence, they now can apply these products 
                  prescriptively — using only the exact amount of product needed — to maximize their yield.</p>
                <p>Better still, these products are contained in easy-to-handle cartridges for quick and clean handling. And the SIMPAS system itself 
                  is designed to fit most planters in the market. So now you can quickly and efficiently treat your fields to make the largest ROI&nbsp;possible.</p>
              </div>
              <div className="col img-col">
                <img 
                  className="gs__img"
                  src={rowUnitPlus.file.url}
                  alt={rowUnitPlus.description}
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section className="powerofplus-testimonials">
          <div className="container text-center flow--thick">
            <div className="testimonial-header row">
              <div className="container--width-sm">
                <h2 className="text-white">Proof From the&nbsp;Field.</h2>
                <p>Don't just listen to us. See what people like you have to say about SIMPAS-applied Solutions.</p>
              </div>
            </div>
            <div className="testimonials row">
              <StaticImage
                src="../images/quote-marks.png"
                alt=""
                className="quote__img"
              />
              <Carousel
                showThumbs={false}
                showStatus={false}
                showArrows={false}
                autoPlay={true}
                interval="8500"
                infiniteLoop={true}
                className="hero--carousel testimonial--carousel"
              >
              <div className="row">
                <div className="col col-sm-3 col-md-4 text-center">
                  <StaticImage
                    src="../images/quote-jason-orr.png"
                    alt=""
                    className="gs__img"
                  />
                </div>
                <div className="col text-left">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci.</p>
                  <p className="name">NAME, State</p>
                </div>
              </div>
              <div className="row">
                <div className="col col-sm-3 col-md-4 text-center">
                  <StaticImage
                    src="../images/quote-jason-orr.png"
                    alt=""
                    className="gs__img"
                  />
                </div>
                <div className="col text-left">
                  <p>22222 Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerci.</p>
                  <p className="name">NAME 2, State 2</p>
                </div>
              </div>
              </Carousel>
            </div>
          </div>
        </section> */}

        <section className="powerofplus-contact bg--white contact-content">
          <div className="container flow">
            <div className="row">
              <div className="col col-md-3 text-left">
                <h2>Learn <br/>More</h2>
                <p>To learn more, fill out this short form, and we'll give you a call within two business days. If we’re unable to connect after the two days, we’ll send you an email with more in-depth information about SIMPAS-applied Solutions®.</p>
              </div>
              <div className="col col-md-8">
                <WufooForm
                  userName="archermalmo"
                  formHash="qlzjzde11efwzu"
                  header="hide"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="powerofplus-disclaimer bg--white">
          <div className="container flow">
            <div className="row">
              <p className="text-center">AZTEC HC is a restricted use pesticide. Important: Always read and follow label instructions. See label for listed weeds and pests. 
                Some products may not be registered for sale in or use in all states or countries. Please check with your local extension service to ensure registration status. 
                AZTEC HC is EPA registered.</p>
            </div>
          </div>
        </section>

      </LayoutLander>
    )
  }
}
export default PowerOfPlus

export const PowerOfPlusPageQuery = graphql`
  query PowerOfPlusPageQuery {
    aztecGif: contentfulAsset(title: {eq: "AZTEC-Animation"}) {
      id
      description
      title
      fluid(maxWidth: 375, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
    }
    rowUnitPlus: contentfulAsset(title: {eq: "red-row-unit-plus"}) {
      id
      description
      title
      fluid(maxWidth: 375, resizingBehavior: PAD) {
        ...GatsbyContentfulFluid
      }
      file {
        url
      }
    }
  }
`
